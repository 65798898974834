import { saveAs } from 'file-saver';
import { cloneDeep, noop } from 'lodash';
import { portfolioActions as actionTypes, routes } from '../constants';
import { controlPanelActions, errorActions, rulesActions, searchSecuritiesActions } from '.';
import { portfolioService } from '../services/portfolio.service';
import { apiUtils } from '../utils';
import { securityDetailsActions } from './security.details.actions';
import { portfolioListActions } from './portfolio-list.actions';
import { getPortfolios } from '../selectors/portfolio.selector';
import { createFilterActions } from "./filter.actions";
import { BwicFilterType } from "../types/filters/FilterState";
import {
    identifiers, multipleCurrencies, ratings, dealsLegalNames, collateralTypes, collateralManagers, esg, euCompliance,
    staticDeals, nonCallEndFrom, nonCallEndTo, reinvestmentEndFrom, reinvestmentEndTo, dateFrom, dateTo, trustees, amr, outOfRI, outOfNC
} from "../utils/filtering/serializers/serializer.definitions";
import { queryStringSerializer } from "../utils/filtering";
import { convertToApiCriteria, getYearsRange } from '../utils/filtering/filter.utils';
import { selectedDateOptions } from "../utils/amr-pipeline-filter.utils";
import { bwicLogActions } from './bwic-log.actions';
import { history } from '../history';
import { emailPreferencesActions } from './email-preferences.actions';
import { latestTransactionActions } from './entities/latest-transaction.actions';
import { arrangersPipelineActions } from './entities/arrangers-pipeline.actions';

const filterActions = createFilterActions(BwicFilterType.Portfolio);

export const portfolioActions = {
    applyFilter,
    reset,
    togglePortfolioExpanded,
    portfolioSelectionChange,
    deletePortfolioConfirmation,
    deletePortfolio,
    portfolioSecuritySelectionChange,
    showBwicDetails,
    hideBwicDetails,
    newPortfolioDialog,
    newPortfolio,
    exportAll,
    createBwicConfirmed,
    expandAllChange,
    updatePortfoliosAlertStatus,
    hideActivePanels,
    applySearchInput,
    resetFilter,
    advancedFilterBlocked
}

function applyFilter() {
    return (dispatch) => {
        dispatch(filterActions.applyFilter());
        dispatch(redirectWithFilterArguments());
    }
}

function applySearchInput() {
    return dispatch => {
        dispatch(hideActivePanels());
        dispatch(redirectWithFilterArguments());
    }
}

function resetFilter() {
    return dispatch => {
        dispatch(filterActions.resetFiltersAndUnselectSavedFilter());
        dispatch(redirectWithFilterArguments());
        dispatch(filterActions.applyFilter());
    }
}

function redirectWithFilterArguments() {
    return (dispatch, getState) => {
        const { filters, searchSecurities } = getState();
        const filterCriteria = getFilterCriteria(filters.portfolio.filter, searchSecurities?.searchTermItems);
        const serializers = [
            identifiers(),
            ratings(),
            multipleCurrencies(),
            dealsLegalNames(),
            collateralTypes(),
            collateralManagers(),
            esg(),
            euCompliance(),
            staticDeals(),
            nonCallEndFrom(),
            nonCallEndTo(),
            reinvestmentEndFrom(),
            reinvestmentEndTo(),
            dateFrom(noop, 'maturityFrom'),
            dateTo(noop, 'maturityTo'),
            dateFrom(noop, 'vintageFrom'),
            dateTo(noop, 'vintageTo'),
            dateFrom(noop, 'closingFrom'),
            dateTo(noop, 'closingTo'),
            trustees(),
            amr(),
            outOfNC(),
            outOfRI(),
        ];
        const queryString = queryStringSerializer.serialize(filterCriteria, serializers);
        history.replace(`${history.location.pathname}${queryString ? '?' : ''}${queryString}`);
    };
}

function getFilterCriteria(filter, searchTermItems) {
    const nonCallEndYears = getYearsRange(filter.nonCallEnd);
    const reinvestmentEndYears = getYearsRange(filter.reinvestmentEnd);

    const nonCallEndDateRange = selectedDateOptions(filter.nonCallEnd);
    const reinvestmentEndDateRange = selectedDateOptions(filter.reinvestmentEnd);

    const defaultDateRange = { dateFrom: undefined, dateTo: undefined };

    const maturityRange = filter.maturity.filter.selectedOption
        ? convertToApiCriteria(filter.maturity.filter.selectedOption, filter.maturity.filter.options)
        : defaultDateRange;
    const vintageRange = filter.vintage.filter.selectedOption
        ? convertToApiCriteria(filter.vintage.filter.selectedOption, filter.vintage.filter.options)
        : defaultDateRange;
    const closingRange = filter.closing.filter.selectedOption
        ? convertToApiCriteria(filter.closing.filter.selectedOption, filter.closing.filter.options)
        : defaultDateRange;

    return {
        isinCusipsAndTickers: searchTermItems,
        currency: filter.currency?.filter.filter(f => f.selected).map(f => f.value),
        ratings: filter.ratings?.filter.filter(f => f.selected).map(f => f.value),
        dealsLegalNames: filter.dealName?.filter.filter(f => f.selected).map(f => f.value),
        collateralTypes: filter.collateralType?.filter.filter(f => f.selected).map(f => f.value),
        collateralManagers: filter.managers?.filter.filter(f => f.selected).map(f => f.value),
        esg: filter.esg?.filter.selectedOption,
        euCompliance: filter.euCompliance?.filter.selectedOption,
        staticDeals: filter.staticDeal?.filter.selectedOption,
        nonCallEndFrom: nonCallEndYears.from ?? nonCallEndDateRange.from,
        nonCallEndTo: nonCallEndYears.to ?? nonCallEndDateRange.to,
        nonCallEndFromDate: nonCallEndDateRange.from,
        nonCallEndToDate: nonCallEndDateRange.to,
        reinvestmentEndFrom: reinvestmentEndYears.from ?? reinvestmentEndDateRange.from,
        reinvestmentEndTo: reinvestmentEndYears.to ?? reinvestmentEndDateRange.to,
        reinvestmentEndFromDate: reinvestmentEndDateRange.from,
        reinvestmentEndToDate: reinvestmentEndDateRange.to,
        maturityFrom: maturityRange.dateFrom,
        maturityTo: maturityRange.dateTo,
        vintageFrom: vintageRange.dateFrom,
        vintageTo: vintageRange.dateTo,
        closingFrom: closingRange.dateFrom,
        closingTo: closingRange.dateTo,
        trustees: filter.trustees?.filter.filter(f => f.selected).map(f => f.value),
        amr: filter.amr?.filter.selectedOption,
        outOfNC: filter.outOfNC?.filter.selectedOption,
        outOfRI: filter.outOfRI?.filter.selectedOption,
    }
}

function reset() {
    return dispatch => {
        dispatch(filterActions.resetFiltersAndUnselectSavedFilter());
        dispatch({ type: actionTypes.RESET });
        dispatch(searchSecuritiesActions.reset());
        dispatch(errorActions.resetError());
        dispatch(controlPanelActions.hide());
        dispatch(latestTransactionActions.reset());
        dispatch(arrangersPipelineActions.reset());
    };
}

function togglePortfolioExpanded(portfolioId) {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.EXPANDED_CHANGE,
            portfolioId
        });

        const { expandedState, expandAll } = getState().portfolio;
        const filteredPortfolios = getPortfolios(getState());

        if (filteredPortfolios.every(p => getExpandedState(p.id) !== expandAll)) {
            dispatch(expandAllChange(!expandAll, expandedState));
        }

        function getExpandedState(portfolioId) {
            return expandedState[portfolioId] == null ? true : expandedState[portfolioId];
        }
    };
}

function portfolioSelectionChange(portfolioId, selectAll) {
    return (dispatch, getState) => {
        const portfolio = getPortfolios(getState()).find(p => p.id === portfolioId);

        if (!portfolio) return;

        const selectedState = {
            ...getState().portfolio.selectedState,
            [portfolioId]: {
                selectAll,
                securitiesSelectedState: selectAll
                    ? apiUtils.normalize(portfolio.securities, p => p.id, () => true)
                    : {}
            }
        };

        dispatch({
            type: actionTypes.PORTFOLIO_SELECTED_CHANGE,
            selectedState
        });
    }
}

function portfolioSecuritySelectionChange(portfolioId, portfolioSecurityId, selected) {
    return (dispatch, getState) => {
        const portfolio = getState().entities.portfolios.items.find(p => p.id === portfolioId);

        if (!portfolio) return;

        const currentSelectedState = getState().portfolio.selectedState;
        const portfolioSelectedState = currentSelectedState[portfolioId]
            ? { ...currentSelectedState[portfolioId] }
            : { securitiesSelectedState: {} };

        const checkSelectedState = selected =>
            portfolio.securities.every(s => Boolean(portfolioSelectedState.securitiesSelectedState[s.id]) === selected)

        portfolioSelectedState.securitiesSelectedState[portfolioSecurityId] = selected;
        portfolioSelectedState.selectAll = checkSelectedState(true)
            ? true
            : checkSelectedState(false) ? false : !!portfolioSelectedState.selectAll;

        const selectedState = {
            ...currentSelectedState,
            [portfolioId]: portfolioSelectedState
        };

        dispatch({
            type: actionTypes.PORTFOLIO_SELECTED_CHANGE,
            selectedState
        });
    }
}

function deletePortfolioConfirmation(visible, portfolio) {
    if (visible) {
        return {
            type: actionTypes.DELETE_PORTFOLIO_CONFIRMATION,
            confirmation: {
                visible,
                portfolio
            }
        };
    }

    return {
        type: actionTypes.DELETE_PORTFOLIO_CONFIRMATION,
        confirmation: {}
    };
}

function deletePortfolio(portfolioId) {
    return (dispatch, getState) => {
        dispatch(deletePortfolioConfirmation());
        portfolioService
            .deletePortfolio(portfolioId)
            .then(deleted)
            .catch(e => dispatch(errorActions.unexpectedError(e)));

        function deleted() {
            dispatch(portfolioListActions.deletePortfolio(portfolioId));
            const { securityDetails, entities } = getState();

            if (securityDetails.security &&
                !entities.portfolios.items.some(p => p.securities.some(s => s.id === securityDetails.security.id))) {
                dispatch(securityDetailsActions.securityDetailsReset());
            }

            if (securityDetails.security &&
                !entities.portfolios.items.some(p => p.securities.some(s => s.id === bwicLogActions.security.id))) {
                dispatch(bwicLogActions.bwicLogReset());
            }
        }
    }
}

function showBwicDetails(selectedSecurity, currentBwicHistoryItem) {
    return {
        type: actionTypes.SHOW_BWIC_DETAILS,
        currentBwicHistoryItem,
        selectedSecurity
    };
}

function hideBwicDetails() {
    return (dispatch, getState) => {
        const { selectedBwic } = getState().portfolio;
        if (selectedBwic) {
            dispatch({ type: actionTypes.SHOW_BWIC_DETAILS });
        }
    };
}

function newPortfolioDialog(visible) {
    return {
        type: actionTypes.NEW_PORTFOLIO_DIALOG,
        newPortfolio: { visible }
    };
}

function newPortfolio(title) {
    return dispatch => {
        dispatch(newPortfolioDialog(false));
        portfolioService
            .createPortfolio(title)
            .then(({ referenceName }) => history.push(routes.editPortfolioUrl(referenceName)))
            .catch(e => dispatch(errorActions.unexpectedError(e)));
    };
}

function setPortfolioExportingStatus(status) {
    return { type: actionTypes.PORTFOLIO_SET_IS_REQUESTING_EXPORT, payload: { status } }
}

function exportAll() {
    return (dispatch, getState) => {
        const { filters, searchSecurities } = getState();
        const filterCriteria = getFilterCriteria(filters.portfolio.filter, searchSecurities?.searchTermItems);
        filterCriteria.identifiers = filterCriteria.isinCusipsAndTickers;
        filterCriteria.currency = filterCriteria.currency.length === 1 ? filterCriteria.currency[0] : undefined;
        filterCriteria.nonCallEndFromYear = filterCriteria.nonCallEndFromDate ? undefined : filterCriteria.nonCallEndFrom;
        filterCriteria.nonCallEndToYear = filterCriteria.nonCallEndToDate ? undefined : filterCriteria.nonCallEndTo;
        filterCriteria.reinvestmentEndFromYear = filterCriteria.reinvestmentEndFromDate ? undefined : filterCriteria.reinvestmentEndFrom;
        filterCriteria.reinvestmentEndToYear = filterCriteria.reinvestmentEndToDate ? undefined : filterCriteria.reinvestmentEndTo;

        dispatch(setPortfolioExportingStatus(true));
        portfolioService
            .exportPortfolios(filterCriteria)
            .then(file => saveAs(file.blob, file.name || 'portfolios.csv'))
            .catch((e) => errorActions.unexpectedError(e))
            .finally(() => dispatch(setPortfolioExportingStatus(false)));
    };
}

function createBwicConfirmed() {
    return (dispatch, getState) => {
        const portfolios = getState().entities.portfolios.items;
        const selectedState = getState().portfolio.selectedState;

        const securities = getSelectedSecurities(portfolios, selectedState);
        history.push(routes.newBWIC, { securities: cloneDeep(groupSecurities(securities)) });
    };
}

function groupSecurities(securities) {
    const grouped = [];

    securities.forEach(s => {
        const duplicate = grouped.find(g => g.securityId === s.securityId);
        if (duplicate) {
            duplicate.size += +s.size;
        } else {
            grouped.push(s);
        }
    });

    return grouped;
}

function getSelectedSecurities(portfolios, selectedState) {
    return portfolios
        .map(p => p.securities.filter(s =>
            selectedState[p.id] &&
            selectedState[p.id].securitiesSelectedState &&
            selectedState[p.id].securitiesSelectedState[s.id]))
        .flat();
}

function expandAllChange(expandAll, expandedState) {
    return {
        type: actionTypes.EXPAND_ALL,
        expandAll,
        expandedState
    };
}

//BWIC Alerts
function updatePortfoliosAlertStatus(list) { // list: [{ portfolioId: number, bwicAlert?: boolean, dealersInventoryAlert?: boolean, issuanceMonitorAlert?: boolean, rollerDeadlineAlert?: boolean}]
    return (dispatch, getState) => {
        const portfolios = apiUtils.normalize(
            getState().entities.portfolios.items,
            portfolio => portfolio.id,
            portfolio => portfolio
        );

        const dataToSend = list.map(x => ({
            ...x,
            bwicAlert: x.bwicAlert ?? portfolios[x.portfolioId].bwicAlert,
            dealersInventoryAlert: x.dealersInventoryAlert ?? portfolios[x.portfolioId].dealersInventoryAlert,
            issuanceMonitorAlert: x.issuanceMonitorAlert ?? portfolios[x.portfolioId].issuanceMonitorAlert,
            rollerDeadlineAlert: x.rollerDeadlineAlert ?? portfolios[x.portfolioId].rollerDeadlineAlert
        }));

        const bwicAlertChanges = list.filter(x => x.bwicAlert != null).map(x => x.portfolioId);
        const inventoryAlertChanges = list.filter(x => x.dealersInventoryAlert != null).map(x => x.portfolioId);
        const issuanceMonitorAlertChanges = list.filter(x => x.issuanceMonitorAlert != null).map(x => x.portfolioId);
        const rollerDeadlineAlertChanges = list.filter(x => x.rollerDeadlineAlert != null).map(x => x.portfolioId);

        dispatch(updateBwicAlertStatusRequest(bwicAlertChanges));
        dispatch(updateInventoryAlertStatusRequest(inventoryAlertChanges));
        dispatch(updateIssuanceMonitorAlertStatusRequest(issuanceMonitorAlertChanges));
        dispatch(updateRollerDeadlineAlertStatusRequest(rollerDeadlineAlertChanges));


        portfolioService.updatePortfoliosAlert(dataToSend)
            .then(() => {
                dispatch(portfolioListActions.updateSendAlertState(list));
                dispatch(updateBwicAlertStatusSuccess(bwicAlertChanges));
                dispatch(updateInventoryAlertStatusSuccess(inventoryAlertChanges));
                dispatch(updateIssuanceMonitorAlertStatusSuccess(issuanceMonitorAlertChanges));
                dispatch(updateRollerDeadlineAlertStatusSuccess(rollerDeadlineAlertChanges));
                dispatch(emailPreferencesActions.request());
            })
            .catch(e => {
                dispatch(updateBwicAlertStatusFailure(bwicAlertChanges));
                dispatch(updateInventoryAlertStatusFailure(inventoryAlertChanges));
                dispatch(updateIssuanceMonitorAlertStatusFailure(issuanceMonitorAlertChanges));
                dispatch(updateRollerDeadlineAlertStatusFailure(rollerDeadlineAlertChanges));
                dispatch(errorActions.unexpectedError(e));
            });
    };
}

function updateBwicAlertStatusRequest(list) {
    return {
        type: actionTypes.PORTFOLIO_UPDATE_ALERT_STATUS_REQUEST,
        payload: { list }
    };
}

function updateBwicAlertStatusSuccess(list) {
    return {
        type: actionTypes.PORTFOLIO_UPDATE_ALERT_STATUS_SUCCESS,
        payload: { list }
    };
}


function updateBwicAlertStatusFailure(list) {
    return {
        type: actionTypes.PORTFOLIO_UPDATE_ALERT_STATUS_FAILURE,
        payload: { list }
    };
}

function updateInventoryAlertStatusRequest(list) {
    return {
        type: actionTypes.UPDATE_INVENTORY_ALERT_STATUS_REQUEST,
        payload: { list }
    };
}

function updateInventoryAlertStatusSuccess(list) {
    return {
        type: actionTypes.UPDATE_INVENTORY_ALERT_STATUS_SUCCESS,
        payload: { list }
    };
};

function updateInventoryAlertStatusFailure(list) {
    return {
        type: actionTypes.UPDATE_INVENTORY_ALERT_STATUS_FAILURE,
        payload: { list }
    };
};

function updateIssuanceMonitorAlertStatusRequest(list) {
    return {
        type: actionTypes.UPDATE_ISSUANCE_MONITOR_ALERT_STATUS_REQUEST,
        payload: { list }
    };
};

function updateIssuanceMonitorAlertStatusSuccess(list) {
    return {
        type: actionTypes.UPDATE_ISSUANCE_MONITOR_ALERT_STATUS_SUCCESS,
        payload: { list }
    };
};

function updateIssuanceMonitorAlertStatusFailure(list) {
    return {
        type: actionTypes.UPDATE_ISSUANCE_MONITOR_ALERT_STATUS_FAILURE,
        payload: { list }
    };
};

function updateRollerDeadlineAlertStatusRequest(list) {
    return {
        type: actionTypes.UPDATE_ROLLER_DEADLINE_ALERT_STATUS_REQUEST,
        payload: { list }
    };
}

function updateRollerDeadlineAlertStatusSuccess(list) {
    return {
        type: actionTypes.UPDATE_ROLLER_DEADLINE_ALERT_STATUS_SUCCESS,
        payload: { list }
    };
}

function updateRollerDeadlineAlertStatusFailure(list) {
    return {
        type: actionTypes.UPDATE_ROLLER_DEADLINE_ALERT_STATUS_FAILURE,
        payload: { list }
    };
}




function hideActivePanels() {
    return dispatch => {
        dispatch(hideBwicDetails());
        dispatch(rulesActions.hide());
        dispatch(securityDetailsActions.securityDetailsReset());
        dispatch(bwicLogActions.bwicLogReset());
    }
}

function advancedFilterBlocked(blocked) {
    return {
        type: actionTypes.ADVANCED_FILTER_BLOCKED,
        payload: { blocked }
    }
}
